body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "EuclidCircular";
  src: local("EuclidCircular"),
    url("./styles/Euclid Circular A Regular.ttf") format("truetype");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

button, .MuiButton-contained,.MuiButton-outlined {
  border-radius: 50px !important;
}